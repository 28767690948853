import React , {useEffect, useRef, useState } from 'react';
import { useCustomization } from '../../CustomizationContext';
import Dialog from '@mui/material/Dialog';
import axios from 'axios';
import './highlights.css';
import {useNavigate, useParams} from "react-router-dom";
import{ ReactComponent as Backicon } from '../images/arrow_back_ios.svg';

const Orghighlights = () => {

    const navigate = useNavigate();
    const { orid } = useParams();
    const { brandlogo , setBrandLogo } = useCustomization();
    const [ highlights, setHighlights ] = useState([]);
    const [ highlightsdetails, setHighlightsDetails ] =  useState(null);
    const [open, setOpen] = useState(false);
    const [videoKey, setVideoKey] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const touchStartRef = useRef(null);
    
    const handleClose = () => {
      setOpen(false);
    };
    
    useEffect(()=> { 
        
      const fetchhighlightsData = async () => {
        try {
          const response = await axios.get(`https://ayatana.xircular.io/api/highlights?filters[organization][id][$eq]=${orid}&populate[media_list][fields]=url&populate[media_list][fields]=alternative_text&populate[media_list][fields]=mime&populate[media_list][fields]=ext`);
            setHighlights(response.data.data);
            setHighlightsDetails(response.data.data[0]);

        } catch (error) {
          console.error('Error fetching data:', error);
        }

      };

      fetchhighlightsData();
      
    },[orid]);

    console.log(orid);

    const handlecarousalclick = (index) => {
               setVideoKey((prevKey) => prevKey + 1);
               setCurrentIndex(index);
    }

    useEffect(()=> {
          
      const fetchorgdetails = async () => {
        try {
          const response = await axios.get(`https://ayatana.xircular.io/api/organizations/${orid}?populate[logo][fields][0]=url`);
          setBrandLogo(response.data.data.attributes.logo.data.attributes.url);
          localStorage.setItem('brandlogo', response.data.data.attributes.logo.data.attributes.url);

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
          fetchorgdetails();


    },[orid,setBrandLogo]);


    const handleTouchStart = (e) => {
      touchStartRef.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e) => {
      const touchEnd = e.changedTouches[0].clientX;
      const touchDifference = touchStartRef.current - touchEnd;
  
      if (touchDifference > 100) {
        // Swiped left, go to next image
        setCurrentIndex((prevIndex) =>
          prevIndex < highlightsdetails.attributes.media_list.data.length - 1 ? prevIndex + 1 : 0
        );
      } else if (touchDifference < -100) {
        // Swiped right, go to previous image
        setCurrentIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : highlightsdetails.attributes.media_list.data.length - 1
        );
      }
    };

  return (
    <div className='highlightscontainer'>

    <div className='highlights-header'>
    <div className='highlights-headerwrapper'>
      <div className='backbox' >
      <Backicon onClick={() => navigate(`/OrgList/${orid}`)} />
      <div className='headerlogo'>
        <img onClick={() => navigate(`/OrgList/${orid}`)} src={`https://ayatana.xircular.io/${brandlogo}`}  alt="Brand Logo" />
      </div> 
      </div>
    </div>
    </div>  

    <div className='highlights-section-wrapper'>
    { 
      highlights.length > 0 ? highlights.map((card) => (
 
         <div className='highlights-section' key={card.id}>
      
      { card.attributes.media_list.data ? card.attributes.media_list.data.slice(0, 1).map(child => (
       
        (child.attributes.mime === "video/mp4" || child.attributes.mime === "video/webm" || child.attributes.mime === "video/ogg") ? 

        <div className='mediawrapper'> <video controls autoPlay loop muted ><source src={`https://ayatana.xircular.io${child.attributes.url}`} type={child.attributes.mime} /></video></div>:
        
        <div key={child.id} className='highlights-section-img' style={{ backgroundImage:`url(https://ayatana.xircular.io/${child.attributes.url})` }}>  </div>
         
           )) : <div> Please Check/Upload Images... </div>
        }

         <div className='highlights-section-content'>

            <div className='highlights-despcontent'>
              <h3> { card.attributes.title } </h3>
              <p> { card.attributes.description.substring(0,85) }... </p>
            </div>

            <button className='viewmorebtn'  onClick={()=>{ setOpen(true); setHighlightsDetails(card); }} > View more </button>

           </div>

         </div> 

       )) : <div> Please Check/Upload Highlights... </div>    

      } 

    </div>

    <Dialog fullScreen  open={open}  onClose={handleClose} >
    
    <div className='highlightsdetails-container'>

    <div className='highlights-header'>
    <div className='highlights-headerwrapper'>
      <div className='backbox' >
      <Backicon onClick={handleClose} />
      <div className='headerlogo'>
       <img onClick={() => navigate(`/OrgList/${orid}`)} src={`https://ayatana.xircular.io/${brandlogo}`}  alt="Brand Logo" />
      </div> 
      </div>
    </div>
    </div>  

       <div className='hddetails'>
       {
          highlightsdetails && highlightsdetails.attributes ? <div className='hddetails-wrapper'>
     
           <div className='hdpicturecontent' 
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}  >  
         
            <div className='hditem'>
            { highlightsdetails.attributes.media_list.data.length > 0 ? (
       
              (highlightsdetails.attributes.media_list.data[currentIndex].attributes.mime === "video/mp4" || highlightsdetails.attributes.media_list.data[currentIndex].attributes.mime === "video/webm" || highlightsdetails.attributes.media_list.data[currentIndex].attributes.mime === "video/ogg") ? 
      
               <video key={videoKey} controls autoPlay loop muted >
               <source key={highlightsdetails.attributes.media_list.data[currentIndex].attributes.mime} 
                src={`https://ayatana.xircular.io${highlightsdetails.attributes.media_list.data[currentIndex].attributes.url}`} 
                type={highlightsdetails.attributes.media_list.data[currentIndex].attributes.mime} /></video>:
              
                <img src={`https://ayatana.xircular.io/${highlightsdetails.attributes.media_list.data[currentIndex].attributes.url}`}
                    alt={ highlightsdetails.attributes.media_list.data[currentIndex].attributes.title} />

                 ) : <div> Please Check/Upload Images... </div>
              }

            </div>
   
            <div className='hdarrowsbox'>
   
            { highlightsdetails.attributes.media_list.data.length > 0 ? highlightsdetails.attributes.media_list.data.map((child) => (
              <div className='hddotsui' 
                   onClick={ ()=>{ handlecarousalclick(highlightsdetails.attributes.media_list.data.indexOf(child)) }}  
                   key={child.id} 
                   style={{backgroundColor:child.attributes.url === highlightsdetails.attributes.media_list.data[currentIndex].attributes.url ? '#fff':'#D4D4D4'}} > 
               </div>
              )) :<div> Please Contact admin</div>  }
    
             </div>  
   
           </div>
          
            <div className='hdtext-content'>
            <h3> { highlightsdetails.attributes.title ? highlightsdetails.attributes.title : "Please Check/Upload Title..." } </h3>
           
              { highlightsdetails.attributes.description ? (
                highlightsdetails.attributes.description.split('\n').map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))
                ) : (
                  <p>Please Check/Upload Description ...</p>
                )}
           
              </div>
            
           </div> : <div> Please Check/Upload Brand Story Details </div>
         }  
   
         </div>
     
       </div>

     </Dialog>

    <br></br>

  </div>
  )
}

export default Orghighlights;
