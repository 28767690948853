import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AlertMessage from "./components/AlertMessage";
import Organisations from "./components/Organisations";
import Aboutus from "./components/Organization/Brand-Story/Aboutus";
import Product from "./components/Organization/Products/Product";
import Events from "./components/Organization/Events-Campaigns/Events";
import Dealoffers from "./components/Organization/Deals/Dealoffers";
import Contactus from "./components/Organization/Contactus/Contactus";
import Galleryone from "./components/Organization/Gallery/Galleryone";
import OrgList from "./components/OrgList";
import Orghighlights from "./components/Organization/Highlights/Orghighlights";
import Threedexp from "./components/Organization/3D-Experience/Threedexp";
import ProductHotspot from "./components/Organization/Hotspot/ProductHotspot";
import Arview from "./components/Organization/AR-Experience/Arview";
import Pagenotfound from "./components/Pagenotfound";
import Aboutusdetails from "./components/Organization/Brand-Story/Aboutusdetails";
import Eventdetails from "./components/Organization/Events-Campaigns/Eventdetails"
import Galleryvideo from "./components/Organization/Gallery/Galleryvideo";
import Productsdetails from "./components/Organization/Products/Productsdetails";

function App() {
  const baseUrl = "/"; // Update this with your base URL or domain name

  return (
    <>
      <Router basename={baseUrl}>
        <Routes>
          <Route path="/:orid" element={<OrgList />} />

          <Route path="/" element={<OrgList />} />

          <Route path="/OrgList/:orid" element={<OrgList />} />

          <Route path="/Organisations/:evid" element={<Organisations />} />  

          <Route path="/:orid/Organization/Brand-Story/Aboutus/:abindex" element={<Aboutus />} />

          <Route path="/:orid/Organization/Brand-Story/Aboutusdetails" element={<Aboutusdetails />} />

          <Route path="/:orid/Organization/Products/Product/:prodcat/:ppage" element={<Product />} />

          <Route path="/:orid/Organization/Products/Productsdetails/:prodcat/:prodid" element={<Productsdetails />} />

          <Route path="/:orid/Organization/Highlights/Orghighlights" element={<Orghighlights />} />

          <Route path="/:orid/Organization/Events-Campaigns/Events/:evtype" element={<Events />} />

          <Route path="/:orid/Organization/Events-Campaigns/Eventdetails/:edid" element={<Eventdetails />} />

          <Route path="/:orid/Organization/Deals/Dealoffers" element={<Dealoffers />} />

          <Route path="/:orid/Organization/Contactus/Contactus" element={<Contactus />} />

          <Route path="/:orid/Organization/Gallery/Galleryone" element={<Galleryone />} />

          <Route path="/:orid/Organization/Gallery/Galleryvideo/:vid" element={<Galleryvideo />} />

          <Route path="/Organization/3D-Experience/Threedexp" element={<Threedexp />} />

          <Route path="/Organization/Hotspot/ProductHotspot" element={<ProductHotspot />} />

          <Route path="/Organization/AR-Experience/Arview" element={<Arview />} />

          <Route path="*" element={<Pagenotfound />} />

        </Routes>
      </Router>
    </>
  );
}

export default App;
