import React, { useState, useEffect } from 'react'
import { useCustomization } from '../../CustomizationContext';
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import './product.css';
import axios from 'axios';
import {useNavigate, useParams} from "react-router-dom";
import{ ReactComponent as Backicon } from '../images/arrow_back_ios.svg';
import{ ReactComponent as Menu } from '../images/menu.svg';
import Button from "@mui/material/Button";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const Product = () => {
     const { organizationid, setBrandLogo, brandlogo, productcategoryid, setProductCategoryId, setProductId, prodcatval, setProdcatval, page, setPage } = useCustomization();    
     const [ productcategory, setProductCategory ] = useState([]);
     const [ catproduct, setCatProduct ] = useState([]);
     const [open, setOpen] = useState(false);
     const navigate = useNavigate();
     let { orid , prodcat , ppage } = useParams();  
     const [totalpagecount, setTotalPageCount] = useState(1);

     const handleChange = (event, value) => {
                 ppage = value;       
                 navigate(`/${orid}/Organization/Products/Product/${prodcatval}/${value}`);
     };

     useEffect(()=> { 

         setPage(ppage);
         localStorage.setItem('productpageno',ppage)

     },[ppage,setPage]);
  

     useEffect(()=> {
        
      const fetchorgdetails = async () => {
        try {
           const response = await axios.get(`https://ayatana.xircular.io/api/organizations/${orid}?populate[logo][fields][0]=url`);
           setBrandLogo(response.data.data.attributes.logo.data.attributes.url);
           localStorage.setItem('brandlogo', response.data.data.attributes.logo.data.attributes.url);
  
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
          fetchorgdetails();
  
  
    },[orid,setBrandLogo]);

     const handleClickOpen = () => {
      setOpen(true);
    };

      const handleClose = () => {
        setOpen(false);
       };
    
     useEffect( () => {
      const fetchProductCategoryData = async () => {
        try {
            const response = await axios.get(`https://ayatana.xircular.io/api/product-categories?populate[products][populate][thumbnail][fields]=url&populate[products][populate][thumbnail][fields]=alternative_text&populate[products][populate][thumbnail][fields]=mime&populate[products][populate][thumbnail][fields]=ext&filters[organization][id][$eq]=${organizationid}&populate[product_variants][populate][media_list][fields]`);
            setProductCategory(response.data.data);
            // setCatProduct(response.data.data[0]);

        } catch (error) {
          console.error('Error fetching data:', error);
        }

      };
  
      fetchProductCategoryData();
      
    },[organizationid]);

    useEffect( () => {
      const fetchProductsdatabyCategory = async () => {
        try {
            const response = await axios.get(`https://ayatana.xircular.io/api/products?filters[product_category][id][$eq]=${productcategoryid}&populate[thumbnail][fields]=url&populate[thumbnail][fields]=mime&pagination[pageSize]=50&pagination[page]=${page}`);
            // setProductCategory(response.data.data);
            setCatProduct(response.data.data);
            setTotalPageCount(response.data.meta.pagination.pageCount)
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchProductsdatabyCategory();
      
    },[productcategoryid,page]);

    // console.log(organizationid);

    console.log("product category array", productcategory);

    console.log("product category id", productcategoryid);
 
  return (
    <div className='productscontainer'> 

     <div className='header'>
     <div className='headerwrapper'>

        <div className='backbox' >
        <Backicon onClick={() => navigate(`/OrgList/${orid}`)} />
        <div className='headerlogo'>
         <img onClick={() => navigate(`/OrgList/${orid}`)} src={`https://ayatana.xircular.io/${brandlogo}`}  alt="Brand Logo" />
        </div> 
        </div>
        <div className='menubox' >
            <Menu onClick={handleClickOpen} />
        </div> 
        </div>
      </div>  

    <div className='products-section-wrapper' >

    <Box sx={{ flexGrow: 1, border: 'none' }}>
    <Grid container spacing={1} style={{border:'none'}}>
    {  
      catproduct.length > 0 ?  catproduct.map(child => (

      <Grid key={child.id} item xs={6} md={6} style={{boxShadow:'none',border:'none'}}>
        <Item style={{padding:'0px',boxShadow:'none',border:'none',cursor:'pointer'}} onClick={()=>{ setProductId(child.id); localStorage.setItem('productid', child.id); navigate(`/${orid}/Organization/Products/Productsdetails/${prodcat}/${child.id}`); }}>       
        <div className='prodcard'>
        { child.attributes.thumbnail.data ? 
          <div className='prodthumbnail'> 
          <img src={`https://ayatana.xircular.io/${child.attributes.thumbnail.data.attributes.url}`} alt="product thumbnail" />
          </div> 
        : <div> Please Check/Upload Product Images </div> } 
        
        <div className='titlebox'>
         <h4> {  child.attributes.title ? child.attributes.title.substring(0,18) : "Please Check/Upload Title..." }...  </h4>
        </div>

        </div>   
        </Item> 
      </Grid>

      ))  : <div> Please Check/Upload Product data..</div>
    }
    </Grid>
   </Box>
   </div>

    <Dialog
  open={open}
  onClose={handleClose} >

  <DialogTitle 
      style={{display:'flex',
      alignItems:'center',
      justifyContent:'space-between',
      padding:'15px',
      borderBottom:'0.5px solid #ddd'}}> 

      <h3 
      style={{flex:2,
      color: '#000',
      fontFamily: 'Montserrat',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      letterSpacing: '0.96px',
      }}> SELECT FROM PRODUCTS </h3> 

    <Button onClick={handleClose} style={{flex:1,display:'flex',alignItems:'center',justifyContent:'flex-end',marginTop:'-10px',marginRight:'-10px'}} > <ClearIcon style={{fontSize:'18px',color:'#000'}} />  </Button> </DialogTitle>
 
    <List sx={{ pt: 0 }} style={{padding:'5px',gap:'0px'}} >
    
    { productcategory.length > 0  ? productcategory.map( element => 
      (
        <ListItem key={element.id} style={{padding:'5px',textAlign:'center'}}  onClick={() => { setProductCategoryId(element.id); localStorage.setItem('productcategoryid', element.id); setProdcatval(productcategory.indexOf(element)); setPage(1); navigate(`/${orid}/Organization/Products/Product/${productcategory.indexOf(element)}/${page}`);  } } >
        <ListItemButton             
            style={{
            padding:'10px',
            color:'#040404',
            textAlign:'center',
            fontFamily: 'Montserrat',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            letterSpacing: '0.96px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            backgroundColor:(prodcatval===productcategory.indexOf(element))?'#D4D4D4':'#fff'
            }}  
            onClick={handleClose} autoFocus >

             { element.attributes.title }

        </ListItemButton>
        </ListItem>
        ))
     : <ListItem> Please Check/Upload Products/Services Data </ListItem>
     }
   
     </List>

    </Dialog>

    <div className='paginationbox'>
 
     <Pagination count={totalpagecount} page={page} onChange={handleChange} variant="outlined" shape="rounded" />
   
    </div>

  </div>
  )
}

export default Product;

