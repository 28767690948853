import React, { useEffect, useState }  from 'react'
import './aboutus.css'
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import { useCustomization } from '../../CustomizationContext';
import{ ReactComponent as Backicon } from '../images/arrow_back_ios.svg';
import{ ReactComponent as Menu } from '../images/menu.svg';
import { useNavigate, useParams } from 'react-router-dom';
import Button from "@mui/material/Button";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import DialogTitle from "@mui/material/DialogTitle";

const Aboutus = () => {

     const [aboutusdata , setAboutusdata] = useState([]);
     const [aboutuscontent , setAboutusContent] = useState(null);
     const [open, setOpen] = useState(false);
     const { brandstorytitle, setBrandstorytitle, setBrandLogo, brandlogo, setBIndex, setBdetailsIndex } = useCustomization();
     const { orid, abindex } = useParams();

     const navigate = useNavigate();

     const handleClose = () => {
            setOpen(false);
      };

      const handleClickOpen = () => {
        setOpen(true);
      };

      console.log(orid);

      useEffect(()=> {
        
        const fetchorgdetails = async () => {
          try {
             const response = await axios.get(`https://ayatana.xircular.io/api/organizations/${orid}?populate[logo][fields][0]=url`);
             setBrandLogo(response.data.data.attributes.logo.data.attributes.url);
             localStorage.setItem('brandlogo', response.data.data.attributes.logo.data.attributes.url);
    
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
            fetchorgdetails();
    
    
      },[orid,setBrandLogo]);

      useEffect(()=>{
        
        const fetchData = async () => {
          try {
            const response = await axios.get(`https://ayatana.xircular.io/api/about-sections?populate[content][populate][media_list][fields]=url&populate[content][populate][media_list][fields]=alternative_text&populate[content][populate][media_list][fields]=mime&populate[content][populate][media_list][fields]=ext&filters[organization][id][$eq]=${orid}`);
             setAboutusdata(response.data.data);
            //  setAboutusContent(response.data.data[0]);

          } catch (error) {
            console.error('Error fetching data:', error);
          }
  
        };
    
         fetchData();
        
      },[orid]);

      useEffect(()=>{
            if(aboutusdata && aboutusdata.length > 0 )
                setAboutusContent(aboutusdata[abindex]);
                setBIndex(abindex); 
                localStorage.setItem('bindex', abindex);

      },[abindex,aboutusdata,setBIndex]);

    // console.log("about us data", aboutusdata);
    // console.log("Title", currentitem);
    // console.log("tab data", aboutuscontent);

  return (
    <div className='aboutuscont'>

          <div className='header'>
          <div className='headerwrapper'>

            <div className='backbox' >
            <Backicon onClick={() => navigate(`/OrgList/${orid}`)} />
              <div className='headerlogo'>
              <img onClick={() => navigate(`/OrgList/${orid}`)} src={`https://ayatana.xircular.io/${brandlogo}`}  alt="Brand Logo" />
              </div> 
            </div>

           <div className='menubox' >
              <Menu onClick={handleClickOpen} />
           </div> 
  
          </div>
       </div>  

    <div className='section-wrapper'>

    { aboutuscontent ? aboutuscontent.attributes.content.data.map(child => ( 

          <div className='section' key={child.id}>
             
           { child.attributes.media_list.data ? child.attributes.media_list.data.slice(0, 1).map(innerchild => (

            (innerchild.attributes.mime === "video/mp4" || innerchild.attributes.mime === "video/webm" || innerchild.attributes.mime === "video/ogg") ? 

              <div className='mediawrapper'> <video controls autoPlay loop muted ><source src={`https://ayatana.xircular.io${innerchild.attributes.url}`} type={innerchild.attributes.mime} /></video></div>:
            
              <div key={innerchild.id} className='section-img' style={{backgroundImage:`url(https://ayatana.xircular.io/${innerchild.attributes.url})`}}>  </div>

              )): <div>  Please Check/Upload Images... </div>
            }
     
         <div className='section-content'>

            <div className='despcontent'>
              <h3> { child.attributes.title ? child.attributes.title : " Please Check/Upload Title... " } </h3>
              <p> { child.attributes.description ? child.attributes.description.substring(0,185) : "Please Check/Upload Description ... " }... </p>
            </div>
            
            <button className='viewmorebtn'  onClick={()=>{       
              setBIndex(abindex); 
              localStorage.setItem('bindex', abindex); 
              setBdetailsIndex(aboutuscontent.attributes.content.data.indexOf(child)); 
              localStorage.setItem('bdetailsindex',aboutuscontent.attributes.content.data.indexOf(child));  
              navigate(`/${orid}/Organization/Brand-Story/Aboutusdetails`); }} 
              > View more </button>
        
          </div>

         </div> 
      
        ))     
        : <div> Please Check/Upload Brand Story Content </div>
      } 

    </div>

    <Dialog
    open={open}
    onClose={handleClose} style={{borderRadius:'16px'}}>
 
   <DialogTitle 
   style={{display:'flex',
   alignItems:'center',
   justifyContent:'space-between',
   padding:'15px',
   borderBottom:'0.5px solid #ddd' }}> 
   <h3 
   style={{flex:2,
   color: '#000',
   fontFamily: 'Montserrat',
   fontSize: '12px',
   fontStyle: 'normal',
   fontWeight: '600',
   lineHeight: 'normal',
   letterSpacing: '0.96px',
   }}> SELECT FROM BRAND STORY </h3> 
   <Button onClick={handleClose} style={{flex:1,display:'flex',alignItems:'center',justifyContent:'flex-end',marginTop:'-10px',marginRight:'-10px'}}> <ClearIcon style={{fontSize:'18px',color:'#000'}} />  </Button> 
   </DialogTitle>
  
     <List sx={{ pt: 0 }} style={{padding:'5px',gap:'0px'}} >
     
     { aboutusdata.length > 0  ? aboutusdata.map( element => 
       (
         <ListItem key={element.id} style={{padding:'5px',textAlign:'center'}} 
           onClick={()=>{    
            setBrandstorytitle(element.attributes.tab_title);
            localStorage.setItem('brandstorytitle', element.attributes.tab_title); 
            setBIndex(aboutusdata.indexOf(element)); 
            localStorage.setItem('bindex', aboutusdata.indexOf(element));
            navigate(`/${orid}/Organization/Brand-Story/Aboutus/${aboutusdata.indexOf(element)}`); }} >
            
         <ListItemButton    
            style={{padding:'10px',
            color:'#040404',
            textAlign:'center',
            fontFamily: 'Montserrat',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            letterSpacing: '0.96px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            backgroundColor:( brandstorytitle===element.attributes.tab_title)?'#D4D4D4':'#fff'
            }} onClick={handleClose} autoFocus >

           { element.attributes.tab_title } 
         </ListItemButton>
         </ListItem>
         ))
      : <ListItem> Please Check/Upload Brand Story Data </ListItem>
      }
    
      </List>

     </Dialog>

     <br></br>

  </div>

  )
}

export default Aboutus;
