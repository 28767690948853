import React, { useState, useEffect, useRef}  from 'react'
import { useCustomization } from '../../CustomizationContext';
import './eventdetails.css';
import '../Brand-Story/aboutusdetails.css'
import {useNavigate, useParams} from "react-router-dom";
import{ ReactComponent as Backicon } from '../images/arrow_back_ios.svg';
import axios from 'axios';

const Eventdetails = () => {

    const navigate = useNavigate();
    const { orid, edid } = useParams();
    const { brandlogo , setBrandLogo, eventtype } = useCustomization();
    const [ eventdetails, setEventDetails ] =  useState(null);
    const [videoKey, setVideoKey] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const touchStartRef = useRef(null);

    useEffect(()=> {
        
        const fetchorgdetails = async () => {
          try {
             const response = await axios.get(`https://ayatana.xircular.io/api/organizations/${orid}?populate[logo][fields][0]=url`);
             setBrandLogo(response.data.data.attributes.logo.data.attributes.url);
             localStorage.setItem('brandlogo', response.data.data.attributes.logo.data.attributes.url);
    
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
            fetchorgdetails();
    
    
      },[orid,setBrandLogo]);

    useEffect(()=>{
      const fetcheventdetails = async () => {
        try {
            const response = await axios.get(`https://ayatana.xircular.io/api/campaigns/${edid}?populate[media_list][fields]=url&populate[media_list][fields]=alternative_text&populate[media_list][fields]=mime&populate[media_list][fields]=ext`);
            setEventDetails(response.data.data);
          } catch (error) {
             console.error('Error fetching data:', error);
         }
      };
  
      fetcheventdetails();
      
    },[edid]);

    const handlecarousalclick = (index) => {
              setVideoKey((prevKey) => prevKey + 1);
              setCurrentIndex(index);
    }

    const handleTouchStart = (e) => {
      touchStartRef.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e) => {
      const touchEnd = e.changedTouches[0].clientX;
      const touchDifference = touchStartRef.current - touchEnd;
  
      if (touchDifference > 100) {
        // Swiped left, go to next image
        setCurrentIndex((prevIndex) =>
          prevIndex < eventdetails.attributes.media_list.data.length - 1 ? prevIndex + 1 : 0
        );
      } else if (touchDifference < -100) {
        // Swiped right, go to previous image
        setCurrentIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : eventdetails.attributes.media_list.data.length - 1
        );
      }
    };
     
   console.log("events details", eventdetails);

  return (
    <div className='eventdetails-container'>

    <div className='header'>
    <div className='headerwrapper'>

      <div className='backbox' >
      <Backicon onClick={() => navigate(`/${orid}/Organization/Events-Campaigns/Events/${eventtype}`)} />
      <div className='headerlogo'>
       <img onClick={() => navigate(`/OrgList/${orid}`)} src={`https://ayatana.xircular.io/${brandlogo}`}  alt="Brand Logo" />
      </div> 
      </div>
     </div>
    </div>  

    <div className='eventdetailssection'>
  {
      eventdetails && eventdetails.attributes ? <div className='eventdetails-wrapper'>

      <div className='edpicturecontent' 
           onTouchStart={handleTouchStart}
           onTouchEnd={handleTouchEnd} >  
    
       <div className='editem' >

          { eventdetails.attributes.media_list.data.length > 0 ? (
     
            (eventdetails.attributes.media_list.data[currentIndex].attributes.mime === "video/mp4" || eventdetails.attributes.media_list.data[currentIndex].attributes.mime === "video/webm" || eventdetails.attributes.media_list.data[currentIndex].attributes.mime === "video/ogg") ? 
    
             <video key={videoKey} controls autoPlay loop muted >
             <source key={ eventdetails.attributes.media_list.data[currentIndex].attributes.mime} 
              src={`https://ayatana.xircular.io${eventdetails.attributes.media_list.data[currentIndex].attributes.url}`} 
              type={ eventdetails.attributes.media_list.data[currentIndex].attributes.mime} /></video>:
            
              <img src={`https://ayatana.xircular.io/${eventdetails.attributes.media_list.data[currentIndex].attributes.url}`}
                  alt={ eventdetails.attributes.media_list.data[currentIndex].attributes.title} />

               ) : <div> Please Check/Upload Images... </div>
            }

       </div>

       <div className='arrowsbox'>

       { (eventdetails.attributes.media_list.data.length > 0 && eventdetails.attributes.media_list.data.length > 1)? eventdetails.attributes.media_list.data.map((child) => (
         <div className='dotsui' onClick={ ()=>{ handlecarousalclick(eventdetails.attributes.media_list.data.indexOf(child))} }  
               key={child.id} 
               style={{
               backgroundColor:child.attributes.url === eventdetails.attributes.media_list.data[currentIndex].attributes.url ? '#fff':'#D4D4D4',
               width:child.attributes.url === eventdetails.attributes.media_list.data[currentIndex].attributes.url ? '12px':'11px',
               height:child.attributes.url === eventdetails.attributes.media_list.data[currentIndex].attributes.url ? '12px':'11px',
               border:child.attributes.url === eventdetails.attributes.media_list.data[currentIndex].attributes.url ? '0.5px solid #000':'none'}} > 
           </div>
         )) :<div> Please Contact admin</div>  }

        </div>  

      </div>
     
      <div className='eventtext-content'>
      <div className='eventcontent'>
        <h3> { eventdetails.attributes.title ? eventdetails.attributes.title : "Please Check/Upload Title..." } </h3>
    
        {eventdetails.attributes.description ? (
          eventdetails.attributes.description.split('\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))
        ) : (
          <p>Please Check/Upload Description ...</p>
        )}

      </div>

      <div className='eventdtcontainer' style={{marginTop:'8px'}}>
            {
                eventdetails.attributes.date ?  <div className='event-date'>
                <h3> Event Date </h3>
                <p> { eventdetails.attributes.date } </p>
                </div> : <div> Please Check/Upload Event Date </div>
            }    

              {
                    eventdetails.attributes.time ?  <div className='event-time'>
                    <h3> Event Time </h3>
                    <p> { eventdetails.attributes.time } </p>
                    </div> : <div> Please Check/Upload Event Time  </div>
              }

       </div>
            {
                eventdetails.attributes.location ? <div className='event-venue'>
                <h3> Venue </h3>  
                <p> { eventdetails.attributes.location } </p> 
                </div> 
                : <div> Please Check/Upload Event Venue </div>
            }

      </div>
       
      </div> : <div> Please Check/Upload Event Details </div>
    }  

    </div>

  </div>
  )
}

export default Eventdetails