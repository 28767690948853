import React, { useState, useEffect, useRef } from 'react';
import { useCustomization } from '../../CustomizationContext';
import './hotspot.css';
import axios from 'axios';

const Hotspot = ({ x, y, onClick, popupContent , image}) => {
    return (
      <button
        className="hotspot"
        style={{ top: x/375, left: y/375 }}
        onClick={onClick}>
      </button>
    );
  };
  
const ProductHotspot = () => {
    const { productvarientid } = useCustomization();
    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState('');
    const [popupImage, setPopupImage] = useState('');
    const [producthotspot, setProductHotspot] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const touchStartRef = useRef(null);
  
    const handleHotspotClick = (popupContent,image) => {
        setShowPopup(!showPopup);
        setPopupContent(popupContent);
        setPopupImage(image)
      };

    useEffect(() => {
        const fetchProducthotspot = async () => {
          try {
            const response = await axios.get(
              `https://ayatana.xircular.io/api/hotspot-images?populate[image][fields]=url&filters[product_variant][id][$eq]=${productvarientid}&populate[hotspot_details][populate][hotspot][populate][image][fields]=url`
            );
            setProductHotspot(response.data.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchProducthotspot();
        
      }, [productvarientid]);
   
      useEffect(() => {
        if (producthotspot.length > 0) {
          setCurrentIndex(0);
        }
      }, [producthotspot]);

      const handleTouchStart = (e) => {
        touchStartRef.current = e.touches[0].clientX;
      };
    
      const handleTouchEnd = (e) => {
        const touchEnd = e.changedTouches[0].clientX;
        const touchDifference = touchStartRef.current - touchEnd;
    
        if (touchDifference > 100) {
          // Swiped left, go to next image
          setCurrentIndex((prevIndex) =>
            prevIndex < producthotspot.length - 1 ? prevIndex + 1 : 0
          );
        } else if (touchDifference < -100) {
          // Swiped right, go to previous image
          setCurrentIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : producthotspot.length - 1
          );
        }
      };
    
  return (
    <div className="hotspotcontainer">
    <div className="carousel" onClick={() => setShowPopup(false)} >
      <div className="image-container"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd} >
            
            {  producthotspot.length > 0 ? (
               <img
                 src={`https://ayatana.xircular.io/${
                    producthotspot[currentIndex].attributes.image.data.attributes
                      .url
                  }`}
                   alt={producthotspot[currentIndex].attributes.title}
                    /> 
                ) : <div style={{margin:'10px auto'}}> Please check with the admin </div>
            }
            {showPopup && (
                <div
                  className="popup"
                >
                  <img src={`https://ayatana.xircular.io/${popupImage}`} alt="Hotspot"/>
                  <p>{popupContent}</p>
                </div>
              )}

          {
            producthotspot && producthotspot.length > 0 && producthotspot.map((element)=>
              (<div key={element.id}>
           {
            element.attributes.hotspot_details.data  && element.attributes.hotspot_details.data.map((child)=>(
                 <div key={child.id}>
                     {console.log(child.attributes.x  , child.attributes.y)}
                     { console.log(child.attributes.hotspot.data.attributes.image.data.attributes.url) }
                     <Hotspot
                     x={child.attributes.x}
                     y={child.attributes.y}
                     onClick={() => handleHotspotClick(child.attributes.hotspot.data.attributes.description)}
                     popupContent={child.attributes.hotspot.data.attributes.description}
                     image={child.attributes.hotspot.data.attributes.image.data.attributes.url}
                    />
                 </div>
               ))
              }

           </div>))
             
            }  
                 
         </div>
      </div>
    </div>
  )
}

export default ProductHotspot
