import React, { useState, useEffect } from 'react';
import { useCustomization } from '../../CustomizationContext';
import Dialog from '@mui/material/Dialog';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import './event.css'
import { useNavigate, useParams } from 'react-router-dom';
import{ ReactComponent as Backicon } from '../images/arrow_back_ios.svg';
import{ ReactComponent as Menu } from '../images/menu.svg';
import Button from "@mui/material/Button";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import DialogTitle from "@mui/material/DialogTitle";


const Events = () => {

    const { orid , evtype } = useParams();
    const [eventdata, setEventdata ] = useState([]);
    const { setBrandLogo, brandlogo, eventtype, setEventType } = useCustomization();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();


    const handleClose = () => {
      setOpen(false);
    };

    const handleClickOpen = () => {
      setOpen(true);
    }; 


    useEffect(()=>{
      
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://ayatana.xircular.io/api/campaigns?filters[organization][id][$eq]=${orid}&populate[media_list][fields]=url&populate[media_list][fields]=alternative_text&populate[media_list][fields]=mime&populate[media_list][fields]=ext`);
            setEventdata(response.data.data);

        } catch (error) {
          console.error('Error fetching data:', error);
        }

      };
  
       fetchData();
    
    },[orid]);

    const uniqueEventTypes = [...new Set(eventdata.map((event) => event.attributes.type))];

    useEffect(()=> {
        
      const fetchorgdetails = async () => {
        try {
           const response = await axios.get(`https://ayatana.xircular.io/api/organizations/${orid}?populate[logo][fields][0]=url`);
           setBrandLogo(response.data.data.attributes.logo.data.attributes.url);
           localStorage.setItem('brandlogo', response.data.data.attributes.logo.data.attributes.url);
  
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
          fetchorgdetails();
  
  
    },[orid,setBrandLogo]);


  return (
    <div className='eventcontainer'>

          <div className='header'>
            <div className='headerwrapper'>

              <div className='backbox' >
              <Backicon onClick={() => navigate(`/OrgList/${orid}`)} />
              <div className='headerlogo'>
              <img onClick={() => navigate(`/OrgList/${orid}`)} src={`https://ayatana.xircular.io/${brandlogo}`}  alt="Brand Logo" />
              </div> 
              </div>

            <div className='menubox' >
                <Menu onClick={handleClickOpen} />
            </div> 
    
            </div>
        </div>  

    <div className='event-section-wrapper'>
    { 
      eventdata.length > 0 ?  eventdata.map((card) => (
           card.attributes.type === evtype ?  
            <div className='event-section' key={card.id}>
      
            { card.attributes.media_list.data ? card.attributes.media_list.data.slice(0, 1).map(innerchild => (

              (innerchild.attributes.mime === "video/mp4" || innerchild.attributes.mime === "video/webm" || innerchild.attributes.mime === "video/ogg") ? 
  
                <div key={innerchild.id} className='mediawrapper'> <video controls autoPlay loop muted ><source src={`https://ayatana.xircular.io${innerchild.attributes.url}`} type={innerchild.attributes.mime} /></video></div>:
              
                <div key={innerchild.id} className='event-section-img' style={{backgroundImage:`url(https://ayatana.xircular.io/${innerchild.attributes.url})`}}>  </div>
  
                )): <div>  Please Check/Upload Images... </div>
              }

         <div className='event-section-content'>

           <div className='event-despcontent'>
              <h3> { card.attributes.title } </h3>
              <p> { card.attributes.description.substring(0,185) }... </p>
            </div>

           <button className='viewmorebtn'  onClick={()=>{ setEventType(card.attributes.type);  navigate(`/${orid}/Organization/Events-Campaigns/Eventdetails/${card.id}`); }} > View more </button>
           
           </div>

          </div> 

        : <div></div>  

       )) : <div> Please Check/Upload Events data...</div>  

      } 

    </div>

    <Dialog
   open={open}
   onClose={handleClose} >
 
   <DialogTitle 
            style={{display:'flex',
            alignItems:'center',
            justifyContent:'space-between',
            padding:'15px',
            borderBottom:'0.5px solid #ddd' }}>   
           <h3 
           style={{flex:2,
           color: '#000',
           fontFamily: 'Montserrat',
           fontSize: '12px',
           fontStyle: 'normal',
           fontWeight: '600',
           lineHeight: 'normal',
           letterSpacing: '0.96px',
           }}> SELECT FROM CAMPAIGNS </h3> 

    <Button onClick={handleClose}  style={{flex:1,display:'flex',alignItems:'center',justifyContent:'flex-end',marginTop:'-10px',marginRight:'-10px'}} > <ClearIcon style={{fontSize:'18px',color:'#000'}} /> </Button> </DialogTitle>
  
     <List sx={{ pt: 0 }} style={{padding:'5px',gap:'0px'}}>
     
     { uniqueEventTypes.length > 0  ? uniqueEventTypes.map( etype => 
       (
         <ListItem key={etype} style={{padding:'5px',textAlign:'center'}} onClick={() => { setEventType(eventtype); localStorage.setItem('eventtype', eventtype); navigate(`/${orid}/Organization/Events-Campaigns/Events/${etype}`); }} disableGutters >
         <ListItemButton 
            style={{padding:'10px',
            color:'#040404',
            textAlign:'center',
            fontFamily: 'Montserrat',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            letterSpacing: '0.96px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            backgroundColor:(eventtype===etype)?'#D4D4D4':'#fff'
            }}  onClick={handleClose} autoFocus >
           { etype } 
         </ListItemButton>
         </ListItem>
         ))
      : <ListItem> Please Check/Upload Event Data </ListItem>
      }
      </List>

     </Dialog>

     <br></br>

  </div>
  )
}

export default Events;