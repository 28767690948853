import React, { useState , useEffect } from 'react'
import { useCustomization } from '../../CustomizationContext';
import './contactus.css'
import axios from 'axios';
import { Link, useNavigate, useParams } from "react-router-dom";
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import{ ReactComponent as Facebookicon } from '../images/facebook.svg'
import{ ReactComponent as Instagramicon } from '../images/instagram.svg'
import{ ReactComponent as Twittericon } from '../images/Twitter-new-logo.svg'
import{ ReactComponent as Youtubeicon } from '../images/youtube.svg'
import{ ReactComponent as LinkedInicon } from '../images/Linkedin.svg'
import{ ReactComponent as Backicon } from '../images/arrow_back_ios.svg';

const Contactus = () => {

  const { orid } = useParams();
  const { brandlogo , setBrandLogo } = useCustomization();
  const [ contactDetails, setContactDetails ] = useState([]);
  const [ contactaddress, setContactAddress ] = useState([]);

  const navigate = useNavigate();

  useEffect(()=> {
        
    const fetchorgdetails = async () => {
      try {
         const response = await axios.get(`https://ayatana.xircular.io/api/organizations/${orid}?populate[logo][fields][0]=url`);
         setBrandLogo(response.data.data.attributes.logo.data.attributes.url);
         localStorage.setItem('brandlogo', response.data.data.attributes.logo.data.attributes.url);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
        fetchorgdetails();


  },[orid,setBrandLogo]);

  useEffect(()=> { 
      
    const fetchaddressData = async () => {
      try {
        const response = await axios.get(`https://ayatana.xircular.io/api/addresses?filters[organization][id][$eq]=${orid}&populate[thumbnail][fields]=url&populate[thumbnail][fields]=alternative_text&populate[thumbnail][fields]=mime&populate[thumbnail][fields]=ext`);
        setContactAddress(response.data.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };

    fetchaddressData();

    const fetchcontactData = async () => {
      try {
        const response = await axios.get(`https://ayatana.xircular.io/api/contacts?filters[organization][id][$eq]=${orid}`);
        setContactDetails(response.data.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };

      fetchcontactData();
 
   },[orid]);

  console.log(orid);

  return (   
    <div className='contactus-container'>

    <div className='header'>
    <div className='headerwrapper'>

      <div className='backbox' >
      <Backicon onClick={() => navigate(`/OrgList/${orid}`)} />
      <div className='headerlogo'>
        <img onClick={() => navigate(`/OrgList/${orid}`)} src={`https://ayatana.xircular.io/${brandlogo}`}  alt="Brand Logo" />
      </div> 
      </div>
     </div>
    </div> 

    <div className='contactus-wrapper'>
   
   { contactaddress.length > 0 ? contactaddress.map((card) => (

       card.attributes.thumbnail.data.attributes.url ?
        <div key={card.id} className='picturecontent' style={{backgroundImage:`url(https://ayatana.xircular.io/${card.attributes.thumbnail.data.attributes.url})`}} >
         </div>
         
      : <div> Please Check/Upload Images... </div>

      )) : <div> Please  Check/Upload Contact Address Data </div>
    }

   { contactDetails.length > 0 ? contactDetails.map((element) => (  
       <div key={element.id} className='text-content'>

       { contactaddress.length > 0 ? contactaddress.map((card) => (
              <div key={card.id} className='address'>
                  <h3> { card.attributes.title } </h3>
                  <p> { card.attributes.address_line1 } </p>
                  <p> { card.attributes.address_line2 } </p>
                  <p> { card.attributes.city } { card.attributes.zipcode } </p>
                  <p> { card.attributes.state}  {card.attributes.country } </p>
              </div>

            )) : <div> Please  Check/Upload Contact Address Data </div>
        }

      <div className='Phoneandmailbox'>
       { element.attributes.phone_number1 ? <div className='phonebox'> <PhoneOutlinedIcon sx={{padding:'10px',border:'1px solid rgba(0, 0, 0, 0.24)',borderRadius:'12px',fontSize:'18px',color:'#130000'}} /> <p>{ element.attributes.phone_number1 } { element.attributes.phone_number2  ? ','+ element.attributes.phone_number2 : ' '} </p> </div> : <div></div>  }  

       { element.attributes.email_1 ? <div className='emailbox'> <MailOutlineOutlinedIcon sx={{padding:'10px',border:'1px solid rgba(0, 0, 0, 0.24)',borderRadius:'12px',fontSize:'18px',color:'#130000'}}  /> <p> { element.attributes.email_1 } { element.attributes.email_2 ? ','+ element.attributes.email_2 : ''} </p> </div> : <div></div> }  
         
       { element.attributes.website ? <div className='websitebox'> <LanguageIcon sx={{padding:'10px',border:'1px solid rgba(0, 0, 0, 0.24)',borderRadius:'12px',fontSize:'18px',color:'#130000'}}  /> <p> { element.attributes.website } </p> </div>  : <div></div> }
          
      </div>
 
   { (element.attributes.facebook || element.attributes.instagram || element.attributes.youtube || element.attributes.twitter) &&
     <div className='socialmediabox'>
       <div id="smtitle"><p>Our Social Media Handles</p></div>
        <div className='iconbox'>
        
         { element.attributes.facebook ? <Link to={element.attributes.facebook}> <div className="facebookwrapper"> <div id="facebookicon"> <Facebookicon style={{backgroundColor:' #3b5998',borderRadius:'4px'}}/> </div>  <div id="icontext">  </div> </div>  </Link> : <div></div> } 

         { element.attributes.instagram ? <Link to={element.attributes.instagram}>  <div className="instagramwrapper" > <div id="instagramicon"> <Instagramicon style={{background:'radial-gradient(farthest-corner at 15% 100%,  #feda75, #fa7e1e , #d62976 , #962fbf, #4f5bd5 )',borderRadius:'4px' }} /></div> <div id="icontext" > </div></div> </Link> : <div></div> } 

         { element.attributes.linkedin ? <Link to={element.attributes.linkedin}>  <div className="linkedinwrapper" > <div id="linkedinicon"> <LinkedInicon style={{ background:'#1DA1F2',borderRadius:'4px'}} /> </div>  <div id="icontext"> </div> </div> </Link> : <div></div> } 

         { element.attributes.youtube ? <Link to={element.attributes.youtube}>  <div className="youtubewrapper" > <div id="youtubeicon"> <Youtubeicon style={{backgroundColor:'#FF0000',borderRadius:'4px'}} /></div> <div id="icontext" >  </div> </div> </Link> : <div></div> } 

         { element.attributes.twitter ? <Link to={element.attributes.twitter}>  <div className="twitterwrapper" > <div id="twittericon"> <Twittericon style={{ background:'#1DA1F2',borderRadius:'4px'}} /> </div>  <div id="icontext"> </div> </div> </Link> : <div></div> } 

           </div>
          </div>
       }
          </div>

          )) : <div> Please Check/Upload Contact Details </div>

        }

     </div>

    </div>
    
  )
}

export default Contactus