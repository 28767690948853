import React, { useState, useEffect, useRef}  from 'react'
import { useCustomization } from '../../CustomizationContext';
import './aboutusdetails.css';
import {useNavigate, useParams} from "react-router-dom";
import{ ReactComponent as Backicon } from '../images/arrow_back_ios.svg';
import axios from 'axios';

const Aboutusdetails = () => {

    const navigate = useNavigate();
    const { orid } = useParams();
    const { brandlogo, bindex, bdetailsindex } = useCustomization();
    const [abcontent , setAbContent] = useState([]);
    const [aboutdetails , setAboutdetails] = useState(null);
    const [videoKey, setVideoKey] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const touchStartRef = useRef(null);

    useEffect(()=>{
      const fetchabcontent = async () => {
        try {
            const response = await axios.get(`https://ayatana.xircular.io/api/about-sections?populate[content][populate][media_list][fields]=url&populate[content][populate][media_list][fields]=alternative_text&populate[content][populate][media_list][fields]=mime&populate[content][populate][media_list][fields]=ext&filters[organization][id][$eq]=${orid}`);
            setAbContent(response.data.data);
          } catch (error) {
             console.error('Error fetching data:', error);
         }
      };
  
       fetchabcontent();
      
    },[orid]);
  
      useEffect(()=>{
        if( abcontent && abcontent.length > 0 )
        {  if(abcontent[bindex].attributes.content.data)
           {
             setAboutdetails(abcontent[bindex].attributes.content.data[bdetailsindex]);
           }
        }     

         },[abcontent,bindex,bdetailsindex]);


       const handlecarousalclick = (index) => {
            setVideoKey((prevKey) => prevKey + 1);
            setCurrentIndex(index);
        }

    const handleTouchStart = (e) => {
      touchStartRef.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e) => {
      const touchEnd = e.changedTouches[0].clientX;
      const touchDifference = touchStartRef.current - touchEnd;
  
      if (touchDifference > 100) {
        // Swiped left, go to next image
        setCurrentIndex((prevIndex) =>
          prevIndex < aboutdetails.attributes.media_list.data.length - 1 ? prevIndex + 1 : 0
        );
      } else if (touchDifference < -100) {
        // Swiped right, go to previous image
        setCurrentIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : aboutdetails.attributes.media_list.data.length - 1
        );
      }
    };

    console.log("aboutus details", aboutdetails);
    

  return (
    <div className='abdetails-container'>

      <div className='header'>
      <div className='headerwrapper'>

        <div className='backbox' >
        <Backicon onClick={() => navigate(`/${orid}/Organization/Brand-Story/Aboutus/${bindex}`)} />
        <div className='headerlogo'>
        <img onClick={() => navigate(`/OrgList/${orid}`)} src={`https://ayatana.xircular.io/${brandlogo}`}  alt="Brand Logo" />
        </div> 
        </div>
      </div>
      </div>  

      <div className='abdetails'>
    {
       aboutdetails && aboutdetails.attributes ? <div className='abdetails-wrapper'>
  
        <div className='abpicturecontent' 
             onTouchStart={handleTouchStart}
             onTouchEnd={handleTouchEnd} >  
      
         <div className='abitem'>

            { aboutdetails.attributes.media_list.data.length > 0 ? (
       
              (aboutdetails.attributes.media_list.data[currentIndex].attributes.mime === "video/mp4" || aboutdetails.attributes.media_list.data[currentIndex].attributes.mime === "video/webm" || aboutdetails.attributes.media_list.data[currentIndex].attributes.mime === "video/ogg") ? 
      
               <video key={videoKey} controls autoPlay loop muted >
               <source key={ aboutdetails.attributes.media_list.data[currentIndex].attributes.mime} 
                src={`https://ayatana.xircular.io${aboutdetails.attributes.media_list.data[currentIndex].attributes.url}`} 
                type={ aboutdetails.attributes.media_list.data[currentIndex].attributes.mime} /></video>:
              
                <img src={`https://ayatana.xircular.io/${aboutdetails.attributes.media_list.data[currentIndex].attributes.url}`}
                    alt={ aboutdetails.attributes.media_list.data[currentIndex].attributes.title} />

                 ) : <div> Please Check/Upload Images... </div>
              }
         </div>

         <div className='arrowsbox'>
         { (aboutdetails.attributes.media_list.data.length > 0 && aboutdetails.attributes.media_list.data.length > 1) ? aboutdetails.attributes.media_list.data.map((child) => (
           <div className='dotsui' onClick={ ()=>{ handlecarousalclick(aboutdetails.attributes.media_list.data.indexOf(child)) } }  
                 key={child.id} 
                 style={{backgroundColor:child.attributes.url === aboutdetails.attributes.media_list.data[currentIndex].attributes.url ? '#fff':'#D4D4D4',
                         width:child.attributes.url === aboutdetails.attributes.media_list.data[currentIndex].attributes.url ? '12px':'11px',
                         height:child.attributes.url === aboutdetails.attributes.media_list.data[currentIndex].attributes.url ? '12px':'11px',
                         border:child.attributes.url === aboutdetails.attributes.media_list.data[currentIndex].attributes.url ? '0.5px solid #000':'none'}} > 
             </div>
           )) : ""  }
 
          </div>  

        </div>
       
         <div className='abtext-content'>
           <h3> { aboutdetails.attributes.title ? aboutdetails.attributes.title : "Please Check/Upload Title..." } </h3>
      
           { aboutdetails.attributes.description ? (
             aboutdetails.attributes.description.split('\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
              ))
            ) : (
              <p>Please Check/Upload Description ...</p>
            )}
      
           </div>
         
        </div> : <div> Please Check/Upload Brand Story Details </div>
      }  

      </div>

    </div>
  )
}

export default Aboutusdetails
