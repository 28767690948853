import React, {useEffect, useState} from 'react'
import './category.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link, useParams } from 'react-router-dom';
import { useCustomization } from './CustomizationContext';
import axios from 'axios';

function Organisations() {

  const { setOrganizationId, setBrandLogo } = useCustomization();
  const [organizationdata , setOrganizationdata] = useState([]);
  const { evid } = useParams();

  useEffect(()=>{
        
    const fetchorganization = async () => {
      try {
        const response2 = await axios.get(`https://ayatana.xircular.io/api/organizations?populate[thumbnail][fields]=url&populate[logo][fields][0]=url&filters[event][id][$eq]=${evid}`);
        setOrganizationdata(response2.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

        fetchorganization();  

  },[evid]);
 
  // console.log(organizationdata);

  return (
    <div className='brandlist'>

   <div className="gridlayout">
  {
    organizationdata.length > 0 ? organizationdata.map(brand => (  
                <div className="category-container" key={brand.id} onClick={()=>{ setOrganizationId(brand.id); localStorage.setItem('organizationid', brand.id); setBrandLogo(brand.attributes.logo.data.attributes.url); localStorage.setItem('brandlogo', brand.attributes.logo.data.attributes.url); }} >
                    <Link to={`/OrgList/${brand.id}`}>
                    <div className="category-item">
        
                  { 
                    brand.attributes.thumbnail.data ?  <div className="category-image">
                      <img src={`https://ayatana.xircular.io/${brand.attributes.thumbnail.data.attributes.url}`} alt="Brand Thumbnail" />   
                       </div> : <div> Please Check/Upload Thumbnail Image</div>
                    }  
                                         
                      <div className='onimgtext'> 
                        <h2> { brand.attributes.name  ? brand.attributes.name : "Please Check/Upload Brand Name" } </h2>
                        { brand.attributes.description ? <p> { brand.attributes.description.substring(0,40) }</p> : <p>Please Check/Upload Brand Content..</p>}
                      </div>
                     
                    <div className='category-text'>
                    { brand.attributes.logo.data ? <div id="logowrapper"><img src={`https://ayatana.xircular.io/${brand.attributes.logo.data.attributes.url}`} alt="logo for brand" />   </div> : <div> Please Check/Upload Brand logo </div> 
                       
                      }  
                          <div id="explorebox"><p> Explore </p> <ArrowForwardIcon color={"#A43607"} fontSize={"small"} /></div>   
                      </div>
                    </div>
                    </Link>
                  </div>
                )) : <div> Please Check/Upload Brands data.. </div>
              }
      </div>
    </div> 
    )
}

export default Organisations;
