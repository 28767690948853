import React ,{ useState, useEffect } from 'react'
import './galleryone.css';
import { useCustomization } from '../../CustomizationContext';
import { useNavigate, useParams } from 'react-router-dom';
import{ ReactComponent as Backicon } from '../images/arrow_back_ios.svg';
import axios from 'axios';

const Galleryvideo = () => {

    const { orid,vid } = useParams();
    const { brandlogo, setBrandLogo } = useCustomization();
    const [galleryviddata, setGalleryVidData] = useState({});
    const navigate = useNavigate();
  
    useEffect(()=> {
          
      const fetchorgdetails = async () => {
        try {
           const response = await axios.get(`https://ayatana.xircular.io/api/organizations/${orid}?populate[logo][fields][0]=url`);
           setBrandLogo(response.data.data.attributes.logo.data.attributes.url);
           localStorage.setItem('brandlogo', response.data.data.attributes.logo.data.attributes.url);
  
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
          fetchorgdetails();
  
    },[orid,setBrandLogo]);

    useEffect(()=> { 
      
        const fetchvideodata = async () => {
          try {
            const response = await axios.get(`https://ayatana.xircular.io/api/galleries/${vid}?populate[media_data][fields]=url&populate[media_data][fields]=alternative_text&populate[media_data][fields]=mime&populate[media_data][fields]=ext`);
            setGalleryVidData(response.data.data);
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchvideodata();
        
       },[vid]);
    
     console.log(galleryviddata);

  return (
    <div className='gallery-container'>
      
    <div className='header'>
    <div className='headerwrapper'>

      <div className='backbox' >
      <Backicon onClick={() => navigate(`/${orid}/Organization/Gallery/Galleryone`)} />
      <div className='headerlogo'>
      <img  src={`https://ayatana.xircular.io/${brandlogo}`}  alt="Brand Logo" />
      </div> 
      </div>
    </div>
    </div>  

  <div className='galimgwrapper'>
  { 
    (galleryviddata.attributes && galleryviddata.attributes.media_data.data.attributes.url) ?

     (galleryviddata.attributes.media_data.data.attributes.mime === "video/mp4" || galleryviddata.attributes.media_data.data.attributes.mime === "video/webm" || galleryviddata.attributes.media_data.data.attributes.mime === "video/ogg") ?
      
      <video controls autoPlay loop muted >
      <source src={`https://ayatana.xircular.io${galleryviddata.attributes.media_data.data.attributes.url}`} type={galleryviddata.attributes.media_data.data.attributes.mime} />
      </video> : <div></div>      
        
      : <div>Please Check with admin for gallery images</div>
     }

     </div>

    </div>
  )
}

export default Galleryvideo
