import React, { useState, useEffect, useRef } from 'react';
import { useCustomization } from '../../CustomizationContext';
import './threedexp.css';
import axios from 'axios';


const Threedexp = () => {
  const { productvarientid, productcolorid } = useCustomization();
  const [productthreed, setProductThreeD] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoScroll, setAutoScroll] = useState(true);
  const touchStartRef = useRef(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {

    const fetchProduct3dexperience = async () => {
      try {
        const response = await axios.get(
          `https://ayatana.xircular.io/api/product-exterior-images?populate=*&filters[product_colour][id][$eq]=${productcolorid}&filters[product_variant][id][$eq]=${productvarientid}&pagination[pageSize]=36`
        );
        setProductThreeD(response.data.data);
        setPending(false);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    setTimeout(() => {
      fetchProduct3dexperience();
    } , 8000)

  }, [productvarientid, productcolorid]);

    console.log(productthreed);
    console.log("Varient id",productvarientid);
    console.log("Color id",productcolorid)


  useEffect(() => {
    if (productthreed.length > 0) {
      setCurrentIndex(0);
    }
  }, [productthreed]);

  
  useEffect(() => {
    let intervalId;

    if (autoScroll) {
      intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex < productthreed.length - 1 ? prevIndex + 1 : 0
        );
      }, 200);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [autoScroll, productthreed.length]);

  // const handleSlideChange = (index) => {
  //   setCurrentIndex(index);
  // };

  const handleAutoScrollToggle = () => {
    setAutoScroll(!autoScroll);
  };

  const handleTouchStart = (e) => {
    touchStartRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const touchEnd = e.changedTouches[0].clientX;
    const touchDifference = touchStartRef.current - touchEnd;

    if (touchDifference > 100) {
      // Swiped left, go to next image
      setCurrentIndex((prevIndex) =>
        prevIndex < productthreed.length - 1 ? prevIndex + 1 : 0
      );
    } else if (touchDifference < -100) {
      // Swiped right, go to previous image
      setCurrentIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : productthreed.length - 1
      );
    }
  };

  return (
    <div className='threedcontainer'>
      <div className='threedwrapper'>
      <div className='btnwrapper'>
      <button onClick={handleAutoScrollToggle}>
        {autoScroll ? 'Stop' : 'Play'}
      </button>
      </div>

   {   pending ? <div className='loader'> </div>:
       <div
          className='carcarousalwrapper'
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          { productthreed.length > 0 ? 
            <div className='carcarousalitem'>
              <img
                src={`https://ayatana.xircular.io/${
                  productthreed[currentIndex].attributes.image.data.attributes
                    .url
                }`}
                alt={productthreed[currentIndex].attributes.title}
              />
            </div>
           : <div style={{margin:'20px auto'}}> Please check with admin .</div>   
         }
         </div> 
        }   
      </div>
    </div>
  );
};

export default Threedexp;
