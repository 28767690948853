import React ,{ useState, useEffect } from 'react'
import './galleryone.css'
import { useCustomization } from '../../CustomizationContext';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate, useParams } from 'react-router-dom';
import{ ReactComponent as Backicon } from '../images/arrow_back_ios.svg';
import Pagination from '@mui/material/Pagination';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    width:'100%',
    height:'100%',
    overflow: 'hidden',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Galleryone = () => {

  const { orid } = useParams();
  const { brandlogo, setBrandLogo } = useCustomization();
  const [open, setOpen] = useState(false);
  const [gallerydata, setGalleryData] = useState([]);
  const [ currentpicture, setCurrentPicture ] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalpagecount, setTotalPageCount] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };


  useEffect(()=> {
        
    const fetchorgdetails = async () => {
      try {
         const response = await axios.get(`https://ayatana.xircular.io/api/organizations/${orid}?populate[logo][fields][0]=url`);
         setBrandLogo(response.data.data.attributes.logo.data.attributes.url);
         localStorage.setItem('brandlogo', response.data.data.attributes.logo.data.attributes.url);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
        fetchorgdetails();

  },[orid,setBrandLogo]);

  const handleClickOpen = (path,index) => {
          setOpen(true);
          setCurrentPicture(path);
          setCurrentIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=> { 
      
    const fetchgalleryData = async () => {
      try {
        const response = await axios.get(`https://ayatana.xircular.io/api/galleries?filters[organization][id][$eq]=${orid}&populate[media_data][fields]=url&populate[media_data][fields]=alternative_text&populate[media_data][fields]=mime&populate[media_data][fields]=ext&pagination[pageSize]=48&pagination[page]=${page}`);
        setGalleryData(response.data.data);
        setTotalPageCount(response.data.meta.pagination.pageCount)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchgalleryData();
    
  },[orid,page]);

  console.log(orid);

  const handlerevbut = () => {
     if(currentIndex > 0){
       setCurrentIndex(prevIndex => prevIndex - 1);
       setCurrentPicture(gallerydata[currentIndex]);
     }
   };

  const handlenextbtn = () => {
     if(currentIndex < gallerydata.length-1){
      setCurrentIndex(prevIndex => prevIndex + 1);
      setCurrentPicture(gallerydata[currentIndex]);
    }
  };

    useEffect(() => {
      if (currentIndex >= 0 && currentIndex < gallerydata.length) {
        setCurrentPicture(gallerydata[currentIndex]);
      }
    }, [currentIndex, gallerydata]);

        // Disable previous button if currentIndex is 0
    const isPrevDisabled = currentIndex <= 0;

    // Disable next button if currentIndex is at the last index
    const isNextDisabled = currentIndex >= gallerydata.length - 1;

  return (
    <div className='gallery-container'>
    
     <div className='header'>
     <div className='headerwrapper'>

       <div className='backbox' >
       <Backicon onClick={() => navigate(`/OrgList/${orid}`)} />
       <div className='headerlogo'>
         <img onClick={() => navigate(`/OrgList/${orid}`)} src={`https://ayatana.xircular.io/${brandlogo}`}  alt="Brand Logo" />
       </div> 
       </div>
     </div>
     </div>  

     <div className='gallery-wrapper'>

      <div className='gallery-grip'>
      { 
        gallerydata.length > 0 ?  gallerydata.map((card) => (

        (card.attributes.media_data.data && card.attributes.media_data.data.attributes.url!=="") ? 

        <div key={card.id} className='gallery-item'  onClick={()=>{ handleClickOpen(card,gallerydata.indexOf(card)) } }>
        { 
          (card.attributes.media_data.data.attributes.mime === "video/mp4" || card.attributes.media_data.data.attributes.mime === "video/webm" || card.attributes.media_data.data.attributes.mime === "video/ogg") ? 

           <div key={card.id} className='mediawrapper' onKeyDown={() => navigate(`/${orid}/Organization/Gallery/Galleryvideo/${card.id}`)}  > 
           <video controls autoPlay loop muted  >
           <source src={`https://ayatana.xircular.io${card.attributes.media_data.data.attributes.url}`} type={card.attributes.media_data.data.attributes.mime} />
           </video>
           </div>
           :<div className='imgwrapper' style={{backgroundImage:`url(https://ayatana.xircular.io/${card.attributes.media_data.data.attributes.url})`}} > </div>
          }

            </div> : <div>Please Check/Upload gallery data</div>
          )) 
          : <div>Please Check/Upload gallery data</div>
        }

      </div>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}>

        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            GALLERY
        </BootstrapDialogTitle>

        <div className='galimgwrapper'>
        { 
          currentpicture.attributes  && currentpicture.attributes.media_data.data.attributes.url ?
          (currentpicture.attributes.media_data.data.attributes.mime === "video/mp4" || currentpicture.attributes.media_data.data.attributes.mime === "video/webm" || currentpicture.attributes.media_data.data.attributes.mime === "video/ogg") ? 
          <video controls autoPlay loop muted >
          <source src={`https://ayatana.xircular.io${currentpicture.attributes.media_data.data.attributes.url}`} type={currentpicture.attributes.media_data.data.attributes.mime} />
          </video> 
          :<img id="image" src={`https://ayatana.xircular.io/${currentpicture.attributes.media_data.data.attributes.url}`} alt="gallery item" />
             
            :<div>Please Check with admin for gallery images</div>
         } 
         </div>

       <DialogActions id="dialog-footer">
        <div className='dialog-footer-wrapper'>

          <p> { currentIndex+1 } of { gallerydata.length } </p>

        <div className='prenextbtnbox'>
        <button className='prebtn' onClick={handlerevbut}  disabled={isPrevDisabled} style={{border:isPrevDisabled ? '1px solid #BEBEBE' : '0.5px solid #040404'}} >  <KeyboardArrowLeftIcon style={{color:isPrevDisabled ? '#BEBEBE' : '#000'}} /> </button>
        <button className='nextbtn' onClick={handlenextbtn} disabled={isNextDisabled} style={{border:isNextDisabled ? '1px solid #BEBEBE' : '0.5px solid #040404'}} > <KeyboardArrowRightIcon style={{color:isNextDisabled ? '#BEBEBE' : '#000'}}  /> </button>
        </div>

        </div>

      </DialogActions>
      </BootstrapDialog>

     </div>

     <div className='paginationbox'>
 
      <Pagination count={totalpagecount} page={page} onChange={handleChange} variant="outlined" shape="rounded" />
     
     </div>

  </div>

  )
}

export default Galleryone;
