import React, { useEffect, useRef, useState }  from 'react'
import axios from 'axios';
import { useCustomization } from './CustomizationContext';
import './orglist.css'
import { useNavigate, useParams } from 'react-router-dom';
import{ ReactComponent as Brandstory } from './Organization/images/apartment.svg';
import{ ReactComponent as Highlights } from './Organization/images/magic_button.svg';
import{ ReactComponent as Category } from './Organization/images/category.svg';
import{ ReactComponent as Gallery } from './Organization/images/gallery_thumbnail.svg';
import{ ReactComponent as Deals } from './Organization/images/receipt_long.svg';
import{ ReactComponent as Event } from './Organization/images/celebration.svg';
import{ ReactComponent as Contactusicon } from './Organization/images/contact_page.svg';
import ClearIcon from '@mui/icons-material/Clear';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import DialogTitle from "@mui/material/DialogTitle";

const OrgList = () => {

  const [orgdata , setOrgdata] = useState(null);
  const [eventthumbnail , setEventThumbnail] = useState(null);
  const { setBrandstorytitle, setOrganizationId, setProductCategoryId, setBrandLogo, setEventType, setProdcatval, setBIndex } = useCustomization();
  const [ orgbg, setOrgBg] = useState("");
  const [open, setOpen] = useState(false);
  const [aboutusdata , setAboutusdata] = useState([]);
  const [ eventlistmenu , setEventlistmenu ] = useState([]);
  const [ productlistcat , setProductListCat] = useState([]);
  const [ menusection, setMenusection ] = useState("");
  const dialogRef = useRef(null);
  let { orid } = useParams();
    orid = orid || 19;
  
  const handleClickOpen = (menusection) => { // Step 2: Modify the handleClickOpen function
       setOpen(true);
       setMenusection(menusection); // Store the category to open in a state variable
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const navigate = useNavigate();

  useEffect(()=> {
    if(orid!==''){
     setOrganizationId(orid); 
     localStorage.setItem('organizationid', orid);
    }

 },[orid,setOrganizationId]);

  useEffect(()=> {
        
    const fetchorgdetails = async () => {
      try {
         const response = await axios.get(`https://ayatana.xircular.io/api/organizations/${orid}?populate=*`);
         setOrgdata(response.data.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
        fetchorgdetails();

     const fetcheventthumbnail = async () => {
      try {
          const response = await axios.get(`https://ayatana.xircular.io/api/organizations/${orid}?populate[event][populate][thumbnail][fields]=url&populate[event][populate][thumbnail][fields]=alternative_text&populate[event][populate][thumbnail][fields]=mime&populate[event][populate][thumbnail][fields]=ext`);
          setEventThumbnail(response.data.data);
          } catch (error) {
           console.error('Error fetching data:', error);
          }
        };

     fetcheventthumbnail();

  },[orid]);

    useEffect(()=> {

    if(orgdata && orgdata.attributes.background.data)
     {   setOrgBg(orgdata.attributes.background.data.attributes.url);
     }

     if(orgdata && orgdata.attributes.logo.data)
     {  setBrandLogo(orgdata.attributes.logo.data.attributes.url);
        localStorage.setItem('brandlogo', orgdata.attributes.logo.data.attributes.url);
     }

    },[orgdata,setBrandLogo]);

  console.log("Organization id", orid);
  console.log("Organization data", orgdata);

  useEffect(()=>{
        
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://ayatana.xircular.io/api/about-sections?filters[organization][id][$eq]=${orid}`);
         setAboutusdata(response.data.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };

     fetchData();
    
  },[orid]);

  useEffect(()=>{
      
    const fetchEventListmenu = async () => {
      try {
        const response = await axios.get(`https://ayatana.xircular.io/api/campaigns?filters[organization][id][$eq]=${orid}`);
        setEventlistmenu(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchEventListmenu();

  },[orid]);

  const uniqueEventTypes = [...new Set(eventlistmenu.map((event) => event.attributes.type))];

  useEffect( () => {

    const fetchProductCategoryData = async () => {
      try {
          const response = await axios.get(`https://ayatana.xircular.io/api/product-categories?populate[products][populate][thumbnail][fields]=url&populate[products][populate][thumbnail][fields]=alternative_text&populate[products][populate][thumbnail][fields]=mime&populate[products][populate][thumbnail][fields]=ext&filters[organization][id][$eq]=${orid}&populate[product_variants][populate][media_list][fields]`);
          setProductListCat(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchProductCategoryData();
    
  },[orid]);


  return (
    <>
    <div className='orgcontainer' style={{backgroundImage:`url(https://ayatana.xircular.io${orgbg})`}}>
    <div className='bgoverlay'>

   <div className='logo-wrapper'>

    { (orgdata && orgdata.attributes.thumbnail.data) ? <img  id="logothumbnail" src={`https://ayatana.xircular.io/${orgdata.attributes.thumbnail.data.attributes.url}`}  alt="Brand Logo" /> : "" } 

    { (eventthumbnail && eventthumbnail.attributes.event.data.attributes.thumbnail.data )? <img id="eventthumbnail" src={`https://ayatana.xircular.io/${eventthumbnail.attributes.event.data.attributes.thumbnail.data.attributes.url}`}  alt="Event Logo" /> :"" } 

    </div> 

     <div className='menulistwrapper'>
      <div className='menulist'>
    <div className='lineone'>

    { (orgdata && orgdata.attributes.brand_story.data.length > 0) ?
          <div className='icon-item' onClick={() => handleClickOpen('BRAND STORY')} >
              <div className='icon-wrapper' >
                <Brandstory />
              </div>

              <div className='icon-text'>
              <h3>BRAND STORY</h3>
              </div>
            </div>
            : "" }  
        
            { (orgdata && orgdata.attributes.galleries.data.length > 0) ?  
              <div className='icon-item'  onClick={() => navigate(`/${orid}/Organization/Gallery/Galleryone`)} >
              <div className='icon-wrapper'>
                <Gallery />
              </div>
              <div className='icon-text'>
              <h3> GALLERY </h3>
              </div>
              </div>
              : "" }  


          { (orgdata && orgdata.attributes.highlights.data.length > 0) ? 
            <div className='icon-item'  onClick={() => navigate(`/${orid}/Organization/Highlights/Orghighlights`)} >
                <div className='icon-wrapper'>
                  <Highlights />
                </div>
                <div className='icon-text'>
                <h3> HIGHLIGHTS </h3>
                </div>
              </div> : "" }  

          { (orgdata && orgdata.attributes.product_categories.data.length > 0) ?
              <div className='icon-item' onClick={() => handleClickOpen('PRODUCT/SERVICE')} >
                <div className='icon-wrapper'>
                  <Category />
                </div>
                <div className='icon-text'>
                <h3> PRODUCTS </h3>
                </div>
                </div> :"" }  

        </div> 

      <div className='linetwo'>

    { (orgdata && orgdata.attributes.campaigns.data.length > 0) ?     
          <div className='icon-item' onClick={() => handleClickOpen('EVENTS/CAMPAIGNS')}>
          <div className='icon-wrapper'>
            <Event />
          </div>
          <div className='icon-text'>
            <h3> CAMPAIGNS </h3>
          </div>
          </div> :""}

    { (orgdata && orgdata.attributes.deals.data.length > 0) ?   
          <div className='icon-item'  onClick={()=>navigate(`/${orid}/Organization/Deals/Dealoffers`)} >
          <div className='icon-wrapper'>
            <Deals />
          </div>
          <div className='icon-text'>
          <h3> DEALS </h3>
          </div>
          </div> : ""}


    { ((orgdata && orgdata.attributes.contact.data.length) || (orgdata && orgdata.attributes.address.data)) ?   
          <div className='icon-item'  onClick={() => navigate(`/${orid}/Organization/Contactus/Contactus`)} >
          <div className='icon-wrapper'>
            <Contactusicon />
          </div>
          <div className='icon-text'>
          <h3>CONTACT US </h3>
          </div>
          </div> 
          : ""}  
    
          </div>
      </div>
     </div>

        <Dialog
          open={open}
          onClose={handleClose} ref={dialogRef} >
      
        <DialogTitle 
        style={{display:'flex',
                alignItems:'center',
                justifyContent:'space-between',
                padding:'15px',
                borderBottom:'0.5px solid #ddd' }}> 
                <h3 
                style={{flex:2,
                color: '#000',
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: 'normal',
                letterSpacing: '0.96px',
                }}> SELECT FROM { menusection } </h3> 
                <Button onClick={handleClose} style={{flex:1,display:'flex',alignItems:'center',justifyContent:'flex-end',marginTop:'-10px',marginRight:'-10px'}} >  <ClearIcon style={{fontSize:'18px',color:'#000'}} />  
                </Button> 
                </DialogTitle>
       
       <List sx={{ pt: 0 }} style={{padding:'5px',gap:'0px'}} >

       { (menusection === "BRAND STORY") ?
          ( aboutusdata.length > 0  ? aboutusdata.map( element => 
            (
              <ListItem key={element.id} style={{padding:'5px',textAlign:'center'}} onClick={() => { 
                setBrandstorytitle(element.attributes.tab_title);
                localStorage.setItem('brandstorytitle', element.attributes.tab_title); 
                setBIndex(aboutusdata.indexOf(element)); 
                localStorage.setItem('bindex', aboutusdata.indexOf(element)); 
                navigate(`/${orid}/Organization/Brand-Story/Aboutus/${aboutusdata.indexOf(element)}`); }}>

              <ListItemButton id="menubtn"
                style={{padding:'10px',
                        color:'#040404',
                        textAlign:'center',
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                        letterSpacing: '0.96px',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center'
                       }}>
                   { element.attributes.tab_title } 
              </ListItemButton>
              </ListItem>
              ))
           : <ListItem>Please Check/Upload Brand Story Data </ListItem>
           )
          : 
           (menusection === "EVENTS/CAMPAIGNS") ?
           (  uniqueEventTypes.length > 0  ? uniqueEventTypes.map( eventtype => 
            (
              <ListItem style={{padding:'5px',textAlign:'center'}} key={eventtype} onClick={()=>{ setEventType(eventtype); localStorage.setItem('eventtype', eventtype); navigate(`/${orid}/Organization/Events-Campaigns/Events/${eventtype}`); }} >
              <ListItemButton id="menubtn"              
                style={{padding:'10px',
                color:'#040404',
                textAlign:'center',
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                letterSpacing: '0.96px',
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
               }}>
                { eventtype } 
              </ListItemButton>
              </ListItem>
              ))
           : <ListItem>Please Check/Upload Events Data </ListItem>
           ) 
         :
        ( productlistcat.length > 0  ? productlistcat.map( element => 
          (
            <ListItem key={element.id} style={{padding:'5px',textAlign:'center'}} onClick={()=>{ setProductCategoryId(element.id); localStorage.setItem('productcategoryid', element.id); setProdcatval(productlistcat.indexOf(element)); localStorage.setItem('ProductIndex', productlistcat.indexOf(element)); navigate(`/${orid}/Organization/Products/Product/${productlistcat.indexOf(element)}/1`); }}>
            <ListItemButton id="menubtn"              
                style={{padding:'10px',
                color:'#040404',
                textAlign:'center',
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                letterSpacing: '0.96px',
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
              }}>
               { element.attributes.title } 
            </ListItemButton>
            </ListItem>
            ))
           : <ListItem>Please Check/Upload Products Data </ListItem>
         )
          
        }
         </List> 
           
        </Dialog>


        <div className='poweredbylogowrapper'>
           <img id="poweredbylogo" src="/powered-ayathana.png" alt="powered by logo"/>
        </div>

       </div>  
      </div>

    </>
  )
}

export default OrgList;
