import React, { useState, useEffect } from 'react';
import './arview.css';
import { useCustomization } from '../../CustomizationContext';
import axios from 'axios';

const Arview = () => {
  const [ardata, setARdata] = useState([]);
  const [currentglbModel, setcurrentglbModel] = useState('');
  const [currentusdzModel, setcurrentusdzModel] = useState('');
  const [currentPoster, setcurrentPoster] = useState('');
  const [loading, setLoading] = useState(true); // State to track loading

  const { productvarientid } = useCustomization();

  useEffect(() => {
    const fetchProductar = async () => {
      try {
        const response = await axios.get(
          `https://ayatana.xircular.io/api/experience-3ds?filters[product_variant][id][$eq]=${productvarientid}&populate[android_media_data][fields]=url&populate[ios_media_data][fields]=url&populate[thumbnail][fields]=url`
        );

        setARdata(response.data.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false regardless of success or error
      }

    };

       fetchProductar();

  }, [productvarientid]);


  useEffect(() => {
    if (ardata.length > 0) {
      const glbdata = ardata[0];
      if (glbdata.attributes.android_media_data.data) {
        setcurrentglbModel(glbdata.attributes.android_media_data.data.attributes.url);
      } else {
        setcurrentglbModel('');
      }

      if (glbdata.attributes.thumbnail.data) {
        setcurrentPoster(glbdata.attributes.thumbnail.data.attributes.url);
      } else {
        setcurrentPoster('');
      }

      if (glbdata.attributes.ios_media_data.data) {
        setcurrentusdzModel(glbdata.attributes.ios_media_data.data.attributes.url);
      } else {
        setcurrentusdzModel('');
      }
    }

  }, [ardata, currentglbModel, currentPoster]);

  const handleModelLoad = () => {
    console.log('Model loaded successfully.');
       setLoading(false); // Set loading to false once the model is loaded
  };

  const handleModelError = () => {
    console.error('Error loading the model.');
       setLoading(false); // Set loading to false if there's an error loading the model
  };

  return (
  <>
      { loading ? ( // Display loader until loading is true
        <div className='loader'> </div>
      ) : currentglbModel !== '' ? (
        <div className="arcontainer">



        <model-viewer
          style={{ width: '100vw', height: '90vh' }}
          ios-src={`https://ayatana.xircular.io/${currentusdzModel}`}
          src={`https://ayatana.xircular.io/${currentglbModel}`}
          poster={`https://ayatana.xircular.io/${currentPoster}`}
          ar
          ar-modes="webxr scene-viewer quick-look"
          shadow-intensity="0.6"
          autoplay
          camera-controls
          camera-orbit="calc(-1.5rad + env(window-scroll-y) * 4rad) calc(0deg + env(window-scroll-y) * 180deg) calc(5m - env(window-scroll-y) * 10m)"
          field-of-view="40deg"
          onModelLoad={handleModelLoad}
          onModelError={handleModelError}
        >
          <div className="progress-bar hide" slot="progress-bar">
            <div className="update-bar"></div>
          </div>

          <button
            style={{
              padding: '15px',
              borderRadius: '6px',
              backgroundColor: '#040404',
              margin: '15px 0px 0px 15px',
              color: '#fff',
              border:'none'
            }}
            slot="ar-button"
            id="ar-button"
          >
            View in your space
          </button>
        </model-viewer>
        </div>
      ) : (
        <div style={{margin:'20px auto'}}> Please Check with the admin for product ar model </div>
      ) }
      </>
  );
};

export default Arview;
