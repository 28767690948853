import React from 'react'
import { Link } from 'react-router-dom'

const Pagenotfound = () => {
  return (
    <div style={{margin:'10px auto',textAlign:'center'}}>
      
        <h3> Page Not Found </h3>
        <br></br>  <br></br> <br></br>
        <Link to={"/"}> Redirect to Home</Link>

    </div>
  )
}

export default Pagenotfound
