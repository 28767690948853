import React, { useState, useEffect, useRef } from 'react'
import{ ReactComponent as Hotspot } from '../images/gesture_select_black.svg';
import{ ReactComponent as Arexperience } from '../images/view_in_ar_black.svg';
import{ ReactComponent as Threedexperience } from '../images/3d_rotation_black.svg';
import { useNavigate, useParams } from "react-router-dom";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useCustomization } from '../../CustomizationContext';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import{ ReactComponent as Backicon } from '../images/arrow_back_ios.svg';
import './product.css';


const Productsdetails = () => {

  const { productcategoryid } = useCustomization();
  const [productdata, setProductData] = useState([]);
  const [productdetails, setProductDetails] = useState(null);
  const [productcarousalurl, setProductCarousalurl] = useState('');
  //product varients for the product it an array
  const [productvarient, setProductVarient] = useState([]);
  const ImageRef = useRef(null);
  const [focusedImageId, setFocusedImageId] = useState(null);
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const touchStartRef = useRef(null);
  const [videoKey, setVideoKey] = useState(0);
  const { setBrandLogo, brandlogo, setProductVarientId, productcolorid, setProductColorId, page} = useCustomization();    
  let { orid , prodcat , prodid } = useParams();


   useEffect(()=> {
        
    const fetchorgdetails = async () => {
      try {
         const response = await axios.get(`https://ayatana.xircular.io/api/organizations/${orid}?populate[logo][fields][0]=url`);
         setBrandLogo(response.data.data.attributes.logo.data.attributes.url);
         localStorage.setItem('brandlogo', response.data.data.attributes.logo.data.attributes.url);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
        fetchorgdetails();


  },[orid,setBrandLogo]);


useEffect(() => {
  const fetchProductsvarients = async () => {
    try {
      const response = await axios.get(
        `https://ayatana.xircular.io/api/product-variants?populate[thumbnail][fields]=url&populate[thumbnail][fields]=ext&populate[media_list][fields]=url&populate[media_list][fields]=alternative_text&populate[media_list][fields]=mime&populate[media_list][fields]=ext&filters[product][id][$eq]=${prodid}&populate[product_colours][fields]=id&populate[product_colours][fields]=title&populate[product_colours][populate][thumbnail][fields]=url&populate[hotspot_images][fields]=id&populate[experience_3_ds][fields]=id&populate[product_exterior_images][fields]=id`
      );
      setProductData(response.data.data);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchProductsvarients();
}, [prodid]);


useEffect(() => {
  if (productdata.length > 0) {
    const firstProductvarient = productdata[0];
    setProductDetails(firstProductvarient);
    setProductVarient(productdata);
  }
}, [productdata]);

useEffect(() => {
  if (productdetails && productdetails.attributes.media_list.data && productdetails.attributes.media_list.data.length > 0) {
    const firstMedia = productdetails.attributes.media_list.data[0];
    setProductCarousalurl(firstMedia.attributes.url);
  }


}, [productdetails]);

useEffect(() => {
  if (productdetails && productdetails.attributes.product_colours.data && productdetails.attributes.product_colours.data.length > 0) {
    const firstMedia = productdetails.attributes.product_colours.data[0];
       setProductColorId(firstMedia.id);
  }
}, [productdetails,setProductColorId]);


       // Set focus to the first image on load
       useEffect(() => {
        if ( ImageRef && ImageRef.current && productcolorid  ===  ImageRef.current.id) {
              ImageRef.current.focus();
              setFocusedImageId(ImageRef.current.id);
        }
      }, [productcolorid]);

    const handleChange = (event) => {
      // setProductVarientTitle(event.target.value);
        setProductDetails(event.target.value);
  };

  const handlecarousalclick = (index) => {
    setVideoKey((prevKey) => prevKey + 1);
    setCurrentIndex(index);
}

  const handleTouchStart = (e) => {
    touchStartRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const touchEnd = e.changedTouches[0].clientX;
    const touchDifference = touchStartRef.current - touchEnd;

    if (touchDifference > 100) {
      // Swiped left, go to next image
      setCurrentIndex((prevIndex) =>
        prevIndex < productdetails.attributes.media_list.data.length - 1 ? prevIndex + 1 : 0
      );
    } else if (touchDifference < -100) {
      // Swiped right, go to previous image
      setCurrentIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : productdetails.attributes.media_list.data.length - 1
      );
    }
  };

console.log('Product Category id', productcategoryid);
console.log('Product data', productdata);
console.log('Product details', productdetails);
console.log('first varient media', productcarousalurl)

    const handleImageClick = (id) => {
      setProductColorId(id);
      localStorage.setItem('productcolorid', id);
      setFocusedImageId(id);
    };

  return (
    <div className='productcarousalcontainer'>
   
        <div className='header'>
        <div className='headerwrapper' >
          <div className='backbox' >
           <Backicon onClick={() => navigate(`/${orid}/Organization/Products/Product/${prodcat}/${page}`)}/> 
          <div className='headerlogo'>
            <img onClick={() => navigate(`/OrgList/${orid}`)} src={`https://ayatana.xircular.io/${brandlogo}`}  alt="Brand Logo" />
          </div> 

          </div>
          </div>
        
        </div>  

    { productdetails ? (
      <div className='productcarousal-wrapper'>

        { productcarousalurl && (
          <div className='pvcarousalitem'  
           onTouchStart={handleTouchStart}
           onTouchEnd={handleTouchEnd}> 

            { productdetails.attributes.media_list.data.length > 0 ? (
       
              (productdetails.attributes.media_list.data[currentIndex].attributes.mime === "video/mp4" || productdetails.attributes.media_list.data[currentIndex].attributes.mime === "video/webm" || productdetails.attributes.media_list.data[currentIndex].attributes.mime === "video/ogg") ? 
               <div className='productvarientwrapper'>
               <video key={videoKey} controls autoPlay loop muted >
               <source key={ productdetails.attributes.media_list.data[currentIndex].attributes.mime} 
                src={`https://ayatana.xircular.io${productdetails.attributes.media_list.data[currentIndex].attributes.url}`} 
                type={ productdetails.attributes.media_list.data[currentIndex].attributes.mime} /></video> </div> :
              
                <div className='productvarientwrapper'>
                <img src={`https://ayatana.xircular.io/${productdetails.attributes.media_list.data[currentIndex].attributes.url}`}
                    alt={ productdetails.attributes.media_list.data[currentIndex].attributes.title} />
                </div>

                 ) : <div> Please Check/Upload Images... </div>
              }


              <div className='carousalarrows'>
              { (productdetails.attributes.media_list.data.length > 0 && productdetails.attributes.media_list.data.length > 1) ? productdetails.attributes.media_list.data.map((child) => (
                <div className='indicator' onClick={ ()=>{ handlecarousalclick(productdetails.attributes.media_list.data.indexOf(child)) } }  
                      key={child.id} 
                      style={{backgroundColor:child.attributes.url === productdetails.attributes.media_list.data[currentIndex].attributes.url ? '#fff':'#D4D4D4',
                      width:child.attributes.url === productdetails.attributes.media_list.data[currentIndex].attributes.url ? '12px':'11px',
                      height:child.attributes.url === productdetails.attributes.media_list.data[currentIndex].attributes.url ? '12px':'11px',
                      border:child.attributes.url === productdetails.attributes.media_list.data[currentIndex].attributes.url ? '0.5px solid #000':'none',
                      }} > 
                  </div>
                )) :""  }
               </div>  

            </div>
        )}

        <div className='productdetails-content'>
        <div className='icon-box'>
              
       { productdetails.attributes.experience_3_ds.data.length > 0 ? 
        <div className='icon-wrapper' onClick={()=>{ setProductVarientId(productdetails.id); localStorage.setItem('productvarientid', productdetails.id); navigate("/Organization/AR-Experience/Arview") }}>
          <Arexperience />
        </div> :"" }

       { productdetails.attributes.product_exterior_images.data.length > 0 ? 
        <div className='icon-wrapper' onClick={()=>{ setProductVarientId(productdetails.id);  localStorage.setItem('productvarientid', productdetails.id); navigate("/Organization/3D-Experience/Threedexp") }}>
          <Threedexperience />
        </div> :"" }

      { productdetails.attributes.hotspot_images.data.length > 0 ?  
        <div className='icon-wrapper' onClick={()=>{ setProductVarientId(productdetails.id);  localStorage.setItem('productvarientid', productdetails.id); navigate("/Organization/Hotspot/ProductHotspot") }}>
        <Hotspot style={{marginTop:'-10px'}} />
        </div> : "" }

      </div>

     {  productvarient.length > 1 ?  
        <div className='changepvbox'>
            <h4>Change Variant </h4>
           {  productvarient.length > 0 ?   
              <FormControl sx={{m:1,minWidth:280}}  size="small" >
               <Select 
               style={{ color:'#040404',
                        fontFamily: 'Montserrat',
                        fontSize: '12px',
                        fontStyle:'normal',
                        fontWeight: '400',
                        letterSpacing: '0.96px',
                        marginLeft:'-10px'
                      }}

                   value={productdetails}
                   onChange={handleChange}
                   inputProps={{ 'aria-label': 'Without label' }}>
                 { productvarient.map((productvarient)=>(
                   <MenuItem style={{color:'#040404',
                   fontFamily: 'Montserrat',
                   fontSize: '12px',
                   fontStyle:'normal',
                   fontWeight: '400',
                   letterSpacing: '0.96px'}} key={productvarient.id} selected value={productvarient}> {productvarient.attributes.title.substring(0,36)} </MenuItem>    
                   ))          
                 }  
               </Select>
               </FormControl> : ""
             } 
        </div> :""}

    <div className='pvdecpbox'>
     { productdetails.attributes.additional_info && <h3 id="additionalinfo">{productdetails.attributes.additional_info}</h3> }  
       <h3>{productdetails.attributes.title || 'Please Check/Upload Product Title'}</h3>
       { productdetails.attributes.price && <p id="prodprice"> { productdetails.attributes.price } { productdetails.attributes.original_price ? <span id="Strikedoutprice">{ productdetails.attributes.original_price }</span>:""} </p> }
        
            { productdetails.attributes.description ? (
              productdetails.attributes.description.split('\n').map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))
             ) : (
              <p>Please Check/Upload Description ...</p>
            )}
          </div>

      { productdetails.attributes.product_colours.data.length > 0  ? <div className='pvcolorpalet'>
         <h3> Colors </h3>
         <div className='pvcolorbox' >
         { productdetails.attributes.product_colours.data.map((colorelement)=>(
            <img tabindex="0" key={colorelement.id}  ref={colorelement.id === focusedImageId ? ImageRef : null} src={`https://ayatana.xircular.io/${colorelement.attributes.thumbnail.data.attributes.url}`} alt={colorelement.attributes.title} onClick={() => handleImageClick(colorelement.id)} /> 
            )) 
          } 
          </div>
         </div>: ""
        }

      { productdetails.attributes.key_features && <div className='pvfeatureslist' >
         <h3>Key Features</h3>   
          <ul>
         { productdetails.attributes.key_features ? (
           productdetails.attributes.key_features.split('\n').map((paragraph, index) => (
            <li key={index}>{paragraph}</li>
          ))
        ) : (
          <li>Please Check/Upload Description ...</li>
        )}  </ul>
         
         </div>
        } 
          
    
      { productdetails.attributes.size  && <div className='pvsizebox'>
         <h3> Sizes</h3>
         <p> { productdetails.attributes.size } </p>
         </div>  
        }  

        </div>
      </div>

    ) : (
      <div>Please check for product varient</div>
    )  }
    </div>
  )
}

export default Productsdetails