import { createContext, useContext, useState } from "react";

const CustomizationContext = createContext({});

export const CustomizationProvider = ({ children }) => {

  const [ productcategoryid, setProductCategoryId ] = useState(() => {
    const storedProductCategoryId = localStorage.getItem('productcategoryid');
    return storedProductCategoryId ? storedProductCategoryId : 0;
  });

  const [ productvarientid, setProductVarientId ] = useState(() => {
    const storedProductVarientId = localStorage.getItem('productvarientid');
    return storedProductVarientId ? storedProductVarientId : 0;
  });

  const [ productcolorid, setProductColorId ] = useState(() => {
    const storedProductColorId = localStorage.getItem('productcolorid');
    return storedProductColorId ? storedProductColorId : 0;
  });

  const [ productid, setProductId ] = useState(() => {
    const storedProductId= localStorage.getItem('productid');
    return storedProductId ? storedProductId: 0;
  });

  const [organizationid, setOrganizationId] = useState(() => {
    const storedOrganizationId = localStorage.getItem('organizationid');
    return storedOrganizationId ? storedOrganizationId : 0;
  });

  const [ brandlogo, setBrandLogo ] =  useState(() => {
    const storedBrandlogo = localStorage.getItem('brandlogo');
    return storedBrandlogo ? storedBrandlogo : "";
  });

    const [ brandstorytitle, setBrandstorytitle ] =  useState(() => {
      const storedBrandstorytitle = localStorage.getItem('brandstorytitle');
      return storedBrandstorytitle ? storedBrandstorytitle : "";
    });


  const [ bindex, setBIndex ] =  useState(() => {
    const storedBIndex = localStorage.getItem('bindex');
    return storedBIndex ? storedBIndex : 0;
  });

  const [ bdetailsindex, setBdetailsIndex ] =  useState(() => {
    const storedBdetailsIndex = localStorage.getItem('bdetailsindex');
    return storedBdetailsIndex ? storedBdetailsIndex : 0;
  });

  const [ eventtype, setEventType ] =  useState(() => {
    const storedEventType = localStorage.getItem('eventtype');
    return storedEventType ? storedEventType : "";
  });

    const [ prodcatval, setProdcatval ] =  useState(() => {
    const storedProductIndex = localStorage.getItem('ProductIndex');
    return storedProductIndex ? storedProductIndex : 0;
  });

  const [ page, setPage ] =  useState(() => {
    const storedProductpageno = localStorage.getItem('productpageno');
    return storedProductpageno ? storedProductpageno : 1;
  });


  return (
    <CustomizationContext.Provider
      value={{      organizationid,
                    setOrganizationId,
                    productcategoryid,
                    setProductCategoryId,
                    productvarientid,
                    setProductVarientId,
                    productcolorid,
                    setProductColorId,
                    productid,
                    setProductId,
                    brandlogo,
                    setBrandLogo,
                    bindex,
                    setBIndex,
                    bdetailsindex,
                    setBdetailsIndex,
                    eventtype,
                    setEventType,
                    prodcatval,
                    setProdcatval,
                    brandstorytitle, 
                    setBrandstorytitle,
                    page, 
                    setPage
            }}>

      {children}
    </CustomizationContext.Provider>
  );
};


export const useCustomization = () => {
  return useContext(CustomizationContext);
};
